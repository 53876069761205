export default Object.freeze({
	notNull: [
		val => {
			// null でも 空文字でもない
			return (!!val && val !== "") || '入力してください'
		}
	],
	posInt: [
		val => {
			const pattern = /^[1-9][0-9]*$/
			return (!!val &&  pattern.test(val)) || '正の整数値を入力してください'
		}
	],
	nonNegInt: [
		val => {
			const pattern = /^[0-9]+$/
			// 0 が falcy のため注意
			return ((!!val || val === 0) && pattern.test(val)) || '0以上の整数値を入力してください'
		}
	],
	notNullPosInt: [
		val => {
			const pattern = /^[1-9][0-9]*$/
			return (!!val && pattern.test(val)) || '正の整数値を入力してください'
		}
	]
})
